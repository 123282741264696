// import { qs, qsa, $on, $delegate } from './utils';

import '../stylesheets/style.scss';
import canvasTxt from 'canvas-txt';
import 'hammerjs';
import { saveAs } from 'file-saver';
import 'blueimp-canvas-to-blob';
import WebFont from 'webfontloader';

WebFont.load({
    custom: {
      families: ['Rough', 'Anatoma', 'Brutal']
    },
    active: function(){ 
        //when fonts loaded
        console.log('all fonts are ready');
        carousel = new Carousel(board)
    }
})
var w, h;
var root = document.documentElement;
var imageToShare; 

var title = 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum quo sit delectus, nostrum repellendus reiciendis, error minus iste repudiandae eum optio atque aliquid velit dolor recusandae placeat voluptas cupiditate vitae.'
var preSource = '- Brutal News from ';
var sentences = [
    "It's brutal",
    "It's rough",
    "It's news",
    "It's humanity sometimes",
    "It's oranges and lemons",
    "It's terrible",
    "It's harsh",
    "It's shit",
    "It's great...not",
    "It's bad",
    "Damn it",
    "It's awful",
    "Lads",
    "Well done hoomans",
    "It's a meme",
    "It's satirical",
    "It's emotional",
    "It's not funny",
]


function createCanvas(title, source) {
    var c = document.createElement('canvas');
    c.setAttribute('width', '1080px');
    c.setAttribute('height', '1080px');
    var ctx = c.getContext('2d');
    canvasTxt.font = 'Rough, Anatoma';
    canvasTxt.align = 'left';
    canvasTxt.vAlign = 'top';
    canvasTxt.fontSize = 48;
    canvasTxt.lineHeight = 66;
    canvasTxt.drawText(ctx, "«" + title + "» \n" + sentences[Math.floor(Math.random() * sentences.length)] + ".", 100, 100, 880, 880);
    canvasTxt.fontSize = 27;
    canvasTxt.drawText(ctx, preSource + '' + source, 100, 953, 880, 880)
    // Add behind elements.
    ctx.globalCompositeOperation = 'destination-over'
    // Now draw!
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, 1080, 1080);

    return c;
}



/* LikeCarousel (c) 2019 Simone P.M. github.com/simonepm - Licensed MIT */

class Carousel {

    constructor(element) {

        this.board = element

        // add first two cards programmatically
        this.push()

        // handle gestures
        this.handle()

    }

    handle() {

        // list all cards
        this.cards = this.board.querySelectorAll('.card')

        // get top card
        this.topCard = this.cards[this.cards.length - 1]

        // get next card
        this.nextCard = this.cards[this.cards.length - 2]

        // if at least one card is present
        if (this.cards.length > 0) {

            // set default top card position and scale
            this.topCard.style.transform =
                'translateX(-50%) translateY(-50%) rotate(0deg) rotateY(0deg) scale(1)'

            // destroy previous Hammer instance, if present
            if (this.hammer) this.hammer.destroy()

            // listen for tap and pan gestures on top card
            this.hammer = new Hammer(this.topCard)
            this.hammer.add(new Hammer.Tap())
            this.hammer.add(new Hammer.Pan({
                position: Hammer.position_ALL,
                threshold: 0
            }))

            // pass events data to custom callbacks
            this.hammer.on('tap', (e) => {
                this.onTap(e)
            })
            this.hammer.on('pan', (e) => {
                this.onPan(e)
            })

        }

    }

    onTap(e) {

        // get finger position on top card
        let propX = (e.center.x - e.target.getBoundingClientRect().left) / e.target.clientWidth

        // get rotation degrees around Y axis (+/- 15) based on finger position
        let rotateY = 15 * (propX < 0.05 ? -1 : 1)

        // enable transform transition
        this.topCard.style.transition = 'transform 100ms ease-out'

        // apply rotation around Y axis
        this.topCard.style.transform =
            'translateX(-50%) translateY(-50%) rotate(0deg) rotateY(' + rotateY + 'deg) scale(1)'

        // wait for transition end
        setTimeout(() => {
            // reset transform properties
            this.topCard.style.transform =
                'translateX(-50%) translateY(-50%) rotate(0deg) rotateY(0deg) scale(1)'
        }, 100)

    }

    onPan(e) {

        if (!this.isPanning) {

            this.isPanning = true
            // remove transition properties
            this.topCard.style.transition = null
            if (this.nextCard) this.nextCard.style.transition = null

            // get top card coordinates in pixels
            let style = window.getComputedStyle(this.topCard)
            let mx = style.transform.match(/^matrix\((.+)\)$/)
            this.startPosX = mx ? parseFloat(mx[1].split(', ')[4]) : 0
            this.startPosY = mx ? parseFloat(mx[1].split(', ')[5]) : 0

            // get top card bounds
            let bounds = this.topCard.getBoundingClientRect()

            // get finger position on top card, top (1) or bottom (-1)
            this.isDraggingFrom =
                (e.center.y - bounds.top) > this.topCard.clientHeight / 2 ? -1 : 1

        }

        // get new coordinates
        let posX = e.deltaX + this.startPosX
        let posY = e.deltaY + this.startPosY

        // get ratio between swiped pixels and the axes
        let propX = e.deltaX / this.board.clientWidth
        let propY = e.deltaY / this.board.clientHeight

        // get swipe direction, left (-1) or right (1)
        let dirX = e.deltaX < 0 ? -1 : 1

        // get degrees of rotation, between 0 and +/- 45
        let deg = this.isDraggingFrom * dirX * Math.abs(propX) * 45

        // get scale ratio, between .95 and 1
        let scale = (95 + (5 * Math.abs(propX))) / 100

        // move and rotate top card
        this.topCard.style.transform =
            'translateX(' + posX + 'px) translateY(' + posY + 'px) rotate(' + deg + 'deg) rotateY(0deg) scale(1)'

        // scale up next card
        if (this.nextCard) this.nextCard.style.transform =
            'translateX(-50%) translateY(-50%) rotate(0deg) rotateY(0deg) scale(' + scale + ')'


        if (propX > 0.000125 && e.direction == Hammer.DIRECTION_RIGHT) {
            this.topCard.classList.add('like');
        } else if (propX <= 0.000125) {
            this.topCard.classList.remove('like')
        }
        if (propX < -0.000125 && e.direction == Hammer.DIRECTION_LEFT) {
            this.topCard.classList.add('dislike');
        } else if (propX >= -0.000125) {
            this.topCard.classList.remove('dislike')
        }

        if (e.isFinal) {

            this.isPanning = false

            let successful = false

            // set back transition properties
            this.topCard.style.transition = 'transform 200ms ease-out'
            if (this.nextCard) this.nextCard.style.transition = 'transform 100ms linear'

            // check threshold and movement direction
            if (propX > 0.25 && e.direction == Hammer.DIRECTION_RIGHT) {
                imageToShare = this.topCard.querySelector('canvas');
                openModal('share');
                successful = true
                // get right border position
                posX = this.board.clientWidth

            } else if (propX < -0.25 && e.direction == Hammer.DIRECTION_LEFT) {

                successful = true
                // get left border position
                posX = -(this.board.clientWidth + this.topCard.clientWidth)

            } 
            // else if (propY < -0.25 && e.direction == Hammer.DIRECTION_UP) {

            //     successful = true
            //     // this.topCard.classList.add('testing');

            //     // get top border position
            //     posY = -(this.board.clientHeight + this.topCard.clientHeight)
            // }

            if (successful) {
                // throw card in the chosen direction
                this.topCard.style.transform =
                    'translateX(' + posX + 'px) translateY(' + posY + 'px) rotate(' + deg + 'deg)'
                // wait transition end
                setTimeout(() => {
                    // remove swiped card
                    this.board.removeChild(this.topCard)

                    // handle gestures on new top card
                    this.handle()
                }, 200)

            } else {

                // reset cards position and size
                this.topCard.style.transform =
                    'translateX(-50%) translateY(-50%) rotate(0deg) rotateY(0deg) scale(1)'
                if (this.nextCard) this.nextCard.style.transform =
                    'translateX(-50%) translateY(-50%) rotate(0deg) rotateY(0deg) scale(0.95)'
                this.topCard.classList.remove('like')
                this.topCard.classList.remove('dislike')
            }

        }

    }

    async push() {
        document.querySelector('.board--reload').classList.add('wait');
        var articles = await getArticles()
        for (let i = 0; i < articles.length; i++) {
            let card = document.createElement('div')
            card.classList.add('card')
            let title = articles[i].title;
            let source = articles[i].source;
            let canvas = createCanvas(title, source)
            card.appendChild(canvas)
            this.board.insertBefore(card, this.board.firstChild)
        }
        this.handle();
        setTimeout(() => {
            document.querySelector('.board--reload').classList.remove('wait');
        }, 1000);
    }

}

let board = document.querySelector('#board')
let carousel; 

// document.addEventListener("DOMContentLoaded", function(event) {
//     // Your code to run since DOM is loaded and ready
// });

document.querySelector('.reload').addEventListener('click', function(e){
    e.preventDefault();
    carousel.push();
})

function getArticles() {
    var xhr = new XMLHttpRequest();

    return new Promise((resolve, reject) => {

        xhr.onreadystatechange = (e) => {
            if (xhr.readyState !== 4) {
                return;
            }

            if (xhr.status === 200) {
                resolve(JSON.parse(xhr.responseText));
            } else {
                console.warn('request_error');
            }
        };

        xhr.open('GET', 'https://brutalnews.herokuapp.com/');
        xhr.send();
    });
}



document.querySelector('.header--close').addEventListener('click', function(){
    closeModal();
})
document.querySelector('.header--about').addEventListener('click', function(){
    openModal('about');
})


function openModal(type){
    document.querySelector('.modal').classList.add(type)
    document.body.classList.add('blackground');
}

function closeModal(){
    document.body.classList.remove('blackground');
    let element = document.querySelector('.modal');
    element.classList.remove(...element.classList);
    element.classList.add('modal');
}

//I'm using "click" but it works with any event
let popups = document.querySelectorAll('.popup');
popups.forEach(popup => {
    popup.addEventListener('click', function(event) {
        var isClickInside = document.querySelector('.popup__container').contains(event.target);
        if (!isClickInside) {
          //the click was outside the specifiedElement, do something
          let activePopups = document.querySelectorAll('.popup');
          activePopups.forEach(element => {
              element.classList.add('hide');
          });
        }
      });
});


document.querySelector('#share-button').addEventListener('click', function(){
    document.querySelector('#social-share').classList.remove('hide');
})

document.querySelector('#download-button').addEventListener('click', function(){
    imageToShare.toBlob(blob => {
        saveAs(blob, "brutalnews.png");
    })
    console.log(imageToShare);
    document.querySelector('#download-done').classList.remove('hide');
})